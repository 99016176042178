
export default class Flow {
    /**
     * Create a Flow object
     *
     * @param {string} strategy FlowStrategy that the flow will implement
     * @param {Logger} logger   Logger module
     */
    constructor(flow, logger) {
        this.logger = logger;
        this.flow = flow;
        this.logger.debug('Flow: constructed flow', this.flow);
    }

    /**
     * Initialise the flow with the given modules
     * @param {Object} modules
     */
    init(modules) {
        this.logger.debug('Flow: initialising flow with modules', modules);

        // first we pass the required modules and/or config to the flow object
        if (modules && typeof modules === 'object') {
            const props = Object.keys(modules);
            for (let i = 0; i < props.length; i += 1) {
                const key = props[i];
                if (Object.hasOwnProperty.call(this.flow, key)) {
                    this.flow[key] = modules[key];
                }
            }
        }

        if (typeof this.flow.metadata !== 'undefined') {
            if (typeof this.flow.metadata.fraud_transaction_id === 'undefined') {
                this.logger.error('fraud_transaction_id was not set');
                this.flow.metadata.fraud_transaction_id = "null_tx"
            }

            if (typeof this.flow.metadata.evinaError !== 'undefined') {
                this.logger.error('error in evina script', this.flow.metadata.evinaError);
            }
        }

        // ... and then we initialise it
        this.flow.init();
    }
}
