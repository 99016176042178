export default class Dispatcher {
    /**
     * Create a event dispatcher
     *
     * @param {Logger} logger
     */
    constructor(logger) {
        this.events = {};
        this.logger = logger;
    }

    /**
     * Dispatch an event
     *
     * @param {string} event   Event name from the events module
     * @param {object} details
     */
    dispatchEvent(event, details) {
        if (this.events[event] === undefined) {
            this.logger.debug(`EventDispatcher: no listeners defined for event ${event} not dispatching`);
            return false;
        }

        this.logger.debug(`EventDispatcher: dispatching event: ${event}`, details);
        this.events[event].listeners.forEach((listener) => {
            listener(details);
        });

        return true;
    }

    /**
     * Bind an event listener to the given event
     *
     * @param {string} event
     * @param {function} callback
     */
    addEventListener(event, callback) {
        if (typeof callback !== 'function') {
            this.logger.error(`EventDispatcher: the listener for event ${event} is not a callable. Got ${typeof callback}`);
            return false;
        }

        if (typeof event !== 'string') {
            this.logger.error('EventDispatcher: event type must be a string');
            return false;
        }

        if (this.events[event] === undefined) {
            this.events[event] = {
                listeners: [],
            };
        }

        this.logger.debug(`EventDispatcher: added listener for event: ${event}`);
        this.events[event].listeners.push(callback);

        return true;
    }

    /**
     * Remove a event listener
     *
     * @param {string} event
     * @param {function} callback
     */
    removeEventListener(event, callback) {
        if (typeof event !== 'string') {
            this.logger.error('EventDispatcher: event type must be a string');
            return false;
        }

        if (this.events[event] === undefined) {
            this.logger.debug('EventDispatcher: not removing event listener from non-existent event');
            return false;
        }

        // eslint-disable-next-line arrow-body-style
        this.events[event].listeners = this.events[event].listeners.filter((listener) => {
            return listener.toString() !== callback.toString();
        });

        return true;
    }
}
