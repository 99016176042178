export const INTEGRATOR_REQUEST_IDENTIFY = 'identify';
export const INTEGRATOR_REQUEST_SUBSCRIBE = 'subscribe';
export const INTEGRATOR_REQUEST_USER_CANCELLED = 'user_cancelled';
export const INTEGRATOR_STATUS_SUCCESS = 'SUCCESS';
export const INTEGRATOR_STATUS_ERROR = 'ERROR';

export class RedirectInfo {
    constructor(requestData) {
        this.code = requestData.code;
        this.url = requestData.url;
    }

    hasUrl() {
        return Object.prototype.hasOwnProperty.call(this, 'url');
    }
}

export class BaseRequest {
    constructor(requestData) {
        this.integrator_request = requestData.integrator_request;
        this.integrator_status = requestData.integrator_status;
        this.reference = requestData.reference;
        this.metadata = requestData.metadata;
    }

    isOfType(type) {
        return this.integrator_request === type;
    }

    isValid() {
        return (
            typeof this.integrator_request !== 'undefined' && this.integrator_request !== ''
            && typeof this.integrator_status !== 'undefined'
            && (
                this.integrator_status === INTEGRATOR_STATUS_SUCCESS
                || this.integrator_status === INTEGRATOR_STATUS_ERROR
            )
            && typeof this.reference !== 'undefined' && this.reference !== ''
        );
    }

    isSuccess() {
        return this.integrator_status === INTEGRATOR_STATUS_SUCCESS;
    }

    isError() {
        return this.integrator_status === INTEGRATOR_STATUS_ERROR;
    }
}

export class SubscribeRequest extends BaseRequest {
    constructor(requestData) {
        super(requestData);

        this.jwt = requestData.jwt;
        this.new = (requestData.new === 'true');
    }

    isValid() {
        return (
            super.isValid()
            && this.integrator_request === INTEGRATOR_REQUEST_SUBSCRIBE
            && this.integrator_status === INTEGRATOR_STATUS_SUCCESS
            && typeof this.jwt !== 'undefined' && this.jwt !== ''
            && typeof this.new !== 'undefined'
        );
    }

    isNewSubscription() {
        return this.new;
    }
}

export class SubscribeErrorRequest extends BaseRequest {
    constructor(requestData) {
        super(requestData);
        this.reason = parseInt(requestData.reason, 10);
    }

    isValid() {
        return (
            super.isValid()
            && this.integrator_request === INTEGRATOR_REQUEST_SUBSCRIBE
            && this.integrator_status === INTEGRATOR_STATUS_ERROR
        );
    }
}

export class IdentifyRequest extends BaseRequest {
    constructor(requestData) {
        super(requestData);
        this.identity = requestData.identity;
    }

    isValid() {
        return (
            super.isValid()
            && this.integrator_request === INTEGRATOR_REQUEST_IDENTIFY
            && this.integrator_status === INTEGRATOR_STATUS_SUCCESS
        );
    }
}

export class IdentifyErrorRequest extends BaseRequest {
    constructor(requestData) {
        super(requestData);
        this.reason = parseInt(requestData.reason, 10);
    }

    isValid() {
        return (
            super.isValid()
            && this.integrator_request === INTEGRATOR_REQUEST_IDENTIFY
            && this.integrator_status === INTEGRATOR_STATUS_ERROR
        );
    }
}

export class UserCancelledRequest extends BaseRequest {
    constructor(requestData) {
        super(requestData);

        this.redirect = typeof requestData.redirect !== 'undefined'
            ? new RedirectInfo(requestData.redirect)
            : {};
    }

    isValid() {
        return (
            super.isValid()
            && this.integrator_request === INTEGRATOR_REQUEST_USER_CANCELLED
            && this.integrator_status === INTEGRATOR_STATUS_SUCCESS
        );
    }

    hasRedirect() {
        return (
            this.redirect !== null
            && typeof this.redirect === 'object'
            && this.redirect instanceof RedirectInfo
            && this.redirect.hasUrl()
        );
    }
}
