
/* eslint-disable no-console */
export const DEBUG = 500;
export const INFO = 400;
export const WARN = 300;
export const ERROR = 200;
export const OFF = 0;

export default {
    levels: {
        DEBUG,
        INFO,
        WARN,
        ERROR,
        OFF,
    },

    level: INFO,

    handler: (event, detail, level) => {
        const data = detail || {};
        if(typeof DD_LOGS === 'undefined' || typeof DD_LOGS.logger === 'undefined'){
            return;
        }
        switch (level) {
        case DEBUG:
            DD_LOGS.logger.debug(event, data);
            break;
        case INFO:
            DD_LOGS.logger.info(event, data);
            break;

        case WARN:
            DD_LOGS.logger.warn(event, data);
            break;

        case ERROR:
            DD_LOGS.logger.error(event, data);
            break;

        default:
            DD_LOGS.logger.log(event, data);
            break;
        }
    },
    log(event, detail, level = INFO) {
        if (level <= this.level) {
            
            var logObject = {};
            
            // dd automatically overwites log content with 'msg' or 'message' field
            // this block suppresses this
            if(typeof detail !== 'undefined'){
                for (const [key, value] of Object.entries(detail)) {
                    if (key == "message" || key == "msg") {
                        logObject["log_"+key] = value;
                    } else{
                        logObject[key] = value;
                    }
                }
            }

            logObject["flow_log"] = true;

            this.handler(event, logObject, level);
        }
    },
    debug(event, detail) {
        this.log(event, detail, DEBUG);
    },
    info(event, detail) {
        this.log(event, detail, INFO);
    },
    warn(event, detail) {
        this.log(event, detail, WARN);
    },
    error(event, detail) {
        this.log(event, detail, ERROR);
    },
    setLevel(level) {
        if(typeof DD_LOGS !== 'undefined' && typeof DD_LOGS.logger !== 'undefined'){
            DD_LOGS.logger.setLevel(level);
        }
    },
    setGlobalContext(context) {
        if(typeof window.DD_LOGS !== 'undefined' && typeof window.DD_LOGS.setGlobalContext !== 'undefined'){
            window.DD_LOGS.setGlobalContext(context);
        }
    },
    setHandler(handler) {
        if (typeof handler === 'function') {
            this.handler = handler;
        }
    },
};
