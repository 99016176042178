export default class Slider {

    constructor(
        maxThreshold,
        sliderImagesContainer,
        imageOne,
        imageTwo,
        sliderElement,
        imageContainer,
        startFromZero,
    ) {
        this.sliderImagesContainer = sliderImagesContainer;
        this.sliderElement = sliderElement;
        this.maxThreshold = maxThreshold;
        this.halfOfWidth = Math.floor(this.sliderElement.clientWidth / 2);
        this.imgWidth = imageOne.clientWidth;
        this.imageOne = imageOne;
        this.imageTwo = imageTwo;
        this.clicked = 0;
        this.imageContainer = imageContainer;
        this.imageTwo.style.width = imageTwo.clientWidth + "px";
        this.eventDispatched = false;
        this.startFromZero = startFromZero
    }

    slideReady(e) {
        /* Prevent any other actions that may occur when moving over the image: */
        e.preventDefault();
        /* The slider is now clicked and ready to move: */
        this.clicked = 1;
        /* Execute a function when the slider is moved: */
        window.addEventListener("mousemove", (e) => { this.slideMove(e) });
        window.addEventListener("touchmove", (e) => { this.slideMove(e) });
    }

    slideFinish() {
        /* The slider is no longer clicked: */
        this.clicked = 0;
    }

    slideMove(e) {
        let pos;
        /* If the slider is no longer clicked, exit this function: */
        if (this.clicked === 0) return false;
        /* Get the cursor's x position: */
        pos = this.getCursorPos(e)
        /* Prevent the slider from being positioned outside the image: */
        //the halfOfWidth here is half of 80px the width I have set the slider to

        if (pos < 0 + this.halfOfWidth) pos = this.halfOfWidth;
        if (pos > this.w - this.halfOfWidth) pos = this.w - this.halfOfWidth;
        /* Execute a function that will resize the overlay image according to the cursor: */
        this.slide(pos);
    }

    getCursorPos(e) {
        let a, x = 0;
        e = (e.changedTouches) ? e.changedTouches[0] : e;
        /* Get the x positions of the image: */
        a = this.img.getBoundingClientRect();
        /* Calculate the cursor's x coordinate, relative to the image: */
        x = e.pageX - a.left;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        return x;
    }

    slide(x) {
        /* Resize the image: */
        if (!this.eventDispatched) {
            this.img.style.width = x== 40 && this.startFromZero ? "0px" : x + "px";
            this.sliderElement.style.left = x== 40 && this.startFromZero ? "0px" : this.img.offsetWidth - (this.sliderElement.offsetWidth / 2) + "px";
        } else {
            this.img.style.width = this.imageOne.clientWidth + "px"
            this.sliderElement.style.left = this.img.offsetWidth - (this.sliderElement.offsetWidth) + "px";
        }

        const slideValue = ((parseInt(this.sliderElement.style.left, 10) / (this.imgWidth - (this.halfOfWidth * 2))) * 100);

        if (slideValue === 0) {
            this.sliderImagesContainer.classList.add('show-slider-element-one');
            this.sliderImagesContainer.classList.remove(
                'show-slider-element-two',
                'show-slider-element-three',
                'show-slider-element-four'
            );
        }

        if (slideValue > 0 && slideValue < this.maxThreshold) {
            if (slideValue < this.maxThreshold * 0.75) {
                this.sliderImagesContainer.classList.add('show-slider-element-two');
                this.sliderImagesContainer.classList.remove(
                    'show-slider-element-one',
                    'show-slider-element-three',
                    'show-slider-element-four'
                );
            } else {
                this.sliderImagesContainer.classList.add('show-slider-element-three');
                this.sliderImagesContainer.classList.remove(
                    'show-slider-element-one',
                    'show-slider-element-two',
                    'show-slider-element-four'
                );
            }
        }

        if (slideValue >= this.maxThreshold) {
            this.sliderImagesContainer.classList.add('show-slider-element-four');
            this.sliderImagesContainer.classList.remove(
                'show-slider-element-one',
                'show-slider-element-two',
                'show-slider-element-three'
            );
        }

        if (slideValue > this.maxThreshold) {
            if (!this.eventDispatched) {
                this.imageContainer.style.width = this.imageOne.clientWidth + "px";
                this.sliderElement.dispatchEvent(new Event("sliderTrigger"));
            }
            this.eventDispatched = true;
        }
    }

    setupSlider(img) {
        this.img = img;
        /* Get the width and height of the img element */
        this.w = this.img.offsetWidth;

        this.img.style.width = this.startFromZero ? 0 : this.halfOfWidth + "px";
        /* Create slider: */
        /* Position the slider on the left side: */

        this.sliderElement.style.left = "0px";
        /* Execute a function when the mouse button is pressed: */
        this.sliderElement.addEventListener("mousedown", (e) => { this.slideReady(e) });
        /* Or touched (for touch screens: */
        this.sliderElement.addEventListener("touchstart", (e) => { this.slideReady(e) });

        /* And another function when the mouse button is released: */
        window.addEventListener("mouseup", (e) => { this.slideFinish(e) });
        /* And released (for touch screens: */
        window.addEventListener("touchend", (e) => { this.slideFinish(e) });
    }

    initComparisons() {
        /* Once for each "slider" element:
        pass the "slider" element as a parameter when executing the setupSlider function: */
        this.setupSlider(this.imageContainer);
    }
}
