import logger from './logger';

const Utilities = {

    /**
     * @param cookieName
     * @param cookieValue
     * @param expiryValue
     */
    setCookie(cookieName, cookieValue, expiryValue = null) {
        if (!expiryValue) {
            const expires = new Date();
            expires.setDate(expires.getDate() + 7); // 1 week
            // eslint-disable-next-line no-param-reassign
            expiryValue = expires.toUTCString();
        }
        document.cookie = `${cookieName}=${cookieValue};expires=${expiryValue};path=/`;
    },

    /**
     * https://www.w3schools.com/js/js_cookies.asp
     * @param cookieName
     * @returns {string}
     */
    getCookie(cookieName) {
        const name = `${cookieName}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieString = decodedCookie.split(';');
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < cookieString.length; i++) {
            let cookieNameValue = cookieString[i];
            while (cookieNameValue.charAt(0) === ' ') {
                cookieNameValue = cookieNameValue.substring(1);
            }
            if (cookieNameValue.indexOf(name) === 0) {
                return cookieNameValue.substring(name.length, cookieNameValue.length);
            }
        }
        return '';
    },

    /**
     * @param cookieName
     * @returns {string|boolean}
     */
    checkCookieExists(cookieName) {
        const cookieValue = this.getCookie(cookieName);
        return cookieValue && cookieValue !== '';
    },

    /**
     * Extracts the value of a query parameter
     *
     * @param {?string} url If not provided it uses the current location
     * @param {string} parameter Parameter name
     * @returns {string}
     */
    getQueryParameter: function getQueryParameter(url, parameter) {
        const queryParams = this.getAllQueryParams(url);
        return queryParams[parameter];
    },

    removeQueryParameter: function removeQueryParameter(key, sourceURL) {
        let rtn = sourceURL.split('?')[0];
        let params = [];
        let paramsArr = [];
        const queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
        if (queryString !== '') {
            paramsArr = queryString.split('&');
            for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
                // eslint-disable-next-line prefer-destructuring
                params = paramsArr[i].split('=')[0];
                if (params === key) {
                    paramsArr.splice(i, 1);
                }
            }
            if (paramsArr.length) rtn = `${rtn}?${paramsArr.join('&')}`;
        }
        return rtn;
    },

    /**
     * Returns an object with all query parameters (key: value)
     *
     * @param {?string} url If not provided it uses the current location
     * @returns {Object}
     */
    getAllQueryParams: function getAllUrlParams(url) {
        let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
        const obj = {};

        if (queryString) {
            [queryString] = queryString.split('#');
            const arr = queryString.split('&');

            for (let i = 0; i < arr.length; i += 1) {
                // eslint-disable-next-line prefer-const
                let [paramName, paramValue] = arr[i].split('=');
                paramName = decodeURIComponent(paramName);
                paramValue = decodeURIComponent(paramValue);

                // A param with no value will default to `false`
                if (typeof paramValue === 'undefined') paramValue = false;

                // If it's part of a numeric array - `param[]` or `param[3]`
                if (paramName.match(/\[(\d+)?]$/)) {
                    const key = paramName.replace(/\[(\d+)?]/, '');
                    if (!obj[key]) obj[key] = [];

                    if (paramName.match(/\[\d+]$/)) {
                        const index = /\[(\d+)]/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    } else {
                        obj[key].push(paramValue);
                    }
                    // If it's part of an associative array param[index]
                    // (param[index][depth] not supported)
                } else if (paramName.match(/\[[\w-]+]$/)) {
                    const key = paramName.replace(/\[[\w-]+]$/, '');
                    if (!obj[key]) obj[key] = {};

                    if (paramName.match(/\[[\w-]+]$/)) {
                        const index = /\[([\w-]+)]$/.exec(paramName)[1];
                        obj[key][index] = paramValue;
                    }
                } else if (!obj[paramName]) {
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else if (obj[paramName].constructor === Array) {
                    obj[paramName].push(paramValue);
                } else {
                    logger.error(`Malformed querystring '${queryString}', unable to parse '${arr[i]}', key '${obj[paramName]}' already parsed.`);
                }
            }
        }

        return obj;
    },

    /**
     * Converts an object to a query string
     *
     * @param {Object} obj The object to be converted to a query string
     * @returns {string}
     */
    serialize: function serialize(obj) {
        const str = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const p in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, p)) {
                str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
            }
        }
        return str.join('&');
    },

    /**
     * Repeatedly tests a condition and executes the callback when it results to true
     *
     * @param {function} condition Condition to be tested
     * @param {function} callback Function that needs to be executed
     */
    waitForConditionAndExecute: function waitForConditionAndExecute(condition, callback) {
        if (!condition()) {
            window.setTimeout(this.waitForConditionAndExecute.bind(this, condition, callback), 10);
        } else {
            callback();
        }
    },

    /**
     *
     * @param {string} src Image source to convert to base64
     * @param {function} callback A function that receives the base64 data
     * @param outputFormat
     */
    toDataURL: function toDataURL(src, callback, outputFormat) {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
            let canvas = document.createElement('canvas');
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const dataURL = canvas.toDataURL(outputFormat);
            callback(dataURL);
            canvas = null;
        };
        img.src = src;

        // Handle cached images
        if (img.complete || img.complete === undefined) {
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
            img.src = src;
        }
    },

    /**
     * @param {string} path1 first part of path
     * @param {string} path2 second part of path
     * @returns {string}
     * 
     * joins two parts of a url path with '/', stripping out any existing '/'s
     * if either parts are empty, the other part is returned.
     * if both are empty, an empty string is returned
     */
    pathJoin(path1, path2) {
        if (path1 == "") {
            return path2
        } 
        if (path2 == "") {
            return path1
        }
        if (path1.endsWith("/")) {
            path1 = path1.substring(0, path1.length - 1);
        }
        if (path2.startsWith("/")) {
            path2 = path2.substring(1, path2.length);
        }
        return path1 + "/" + path2
    },

};

export default Utilities;
