import Utilities from './utilities';
import * as IntegratorAPI from './integrator_requests';

class Integrator {
    /**
     * @param {string} baseUrl
     * @param {Object} baseData
     * @param {Logger} logger
     */
    constructor(baseUrl, baseData, logger, landingPageSubscribe) {
        this.baseUrl = baseUrl;
        this.baseData = baseData;
        this.logger = logger;
        this.landingPageSubscribe = landingPageSubscribe;

        this.requestData = Utilities.getAllQueryParams();
    }

    /**
     * Add data
     * @param {Object} data
     */
    appendBaseData(data) {
        this.baseData = Object.assign(this.baseData, data);
    }

    /**
     * Perform an initialisation call
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    initRenderedRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'lp/init/rendered', payload, onSuccess, onError);
    }

    /**
     * Creates an iframe and loads a URL in it
     * @param {Object} data
     */
    heIframeIdentify(data) {
        const payload = Object.assign({}, this.baseData, data);
        this.loadIframe('identify-frame', 'lp/he/identify', payload);
    }

    /**
     * Creates an iframe and loads the supplied URL and endpoint in it.
     * This is for situations when there is a separate integrator and HE service
     * @param {string} url
     * @param {string} endpoint
     * @param {Object} data
     */
    customHeIframeIdentify(url, endpoint, data) {
        const payload = Object.assign({}, this.baseData, data);
        this.loadUrlIframe('identify-frame', url, endpoint, payload);
    }

    customHeIframeIdentifyNoBaseData(url, endpoint, data) {
        this.loadUrlIframe('identify-frame', url, endpoint, data);
    }

    /**
     * Creates an iframe and loads the supplied URL and endpoint in it.
     * This is for situations when there is a separate integrator and HE service
     * Uses integrator base url
     * @param {string} endpoint
     * @param {Object} data
     */
    customHeIframeIdentifyBaseUrl(endpoint, data) {
        const payload = Object.assign({}, this.baseData, data);
        this.loadUrlIframe('identify-frame', this.baseUrl, endpoint, payload);
    }

    /**
     * Creates an iframe and loads an URL in it
     * @param {Object} data
     */
    heIframeSubscribe(data) {
        const payload = Object.assign({}, this.baseData, data);
        this.loadIframe('subscribe-frame', 'lp/he/subscribe', payload);
    }

    /**
     * Perform a GET identify request
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    heIdentifyGetRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('GET', 'lp/he/identify', payload, onSuccess, onError);
    }

    /**
     * Perform a GET identify request
     * @param {Object} data
     * @param {string} endpoint
     * @param {function} onSuccess
     * @param {function} onError
     */
    customHeIdentifyGetRequest(data, endpoint, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('GET', 'lp/he/identify', payload, onSuccess, onError);
    }

    /**
     * Perform a GET identify request
     * @param {Object} data
     * @param {string} endpoint
     * @param {function} onSuccess
     * @param {function} onError
     */
    customHeIdentifyGetRequestWithAuth(data, endpoint, bearer, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequestWithAuth('GET', endpoint, bearer, payload, onSuccess, onError);
    }

    /**
     * Perform a POST identify request
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    heIdentifyPostRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'lp/he/identify', payload, onSuccess, onError);
    }

    /**
     * Perform a subscribe call when Header Enrichment is available
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    heSubscribeRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'lp/he/subscribe', payload, onSuccess, onError);
    }

    heSubscribeRequestUsingTestMakeRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequestTest('POST', 'lp/he/subscribe', payload, onSuccess, onError);
    }

    /**
     * Perform a subscribe call to a custom endpoint when Header Enrichment is available
     * @param {Object} data
     * @param {string} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    customHeSubscribeRequest(data, endpoint, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', endpoint, payload, onSuccess, onError);
    }

    LPSubscribeRequest(data, endpoint, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        if (this.landingPageSubscribe) {
            this.makeRequestToLP('POST', 'integrator/subscribe/' + endpoint, payload, onSuccess, onError);
        } else {
            this.makeRequest('POST', endpoint, payload, onSuccess, onError);
        }
    }

    LPOtpSubscribeCustomRequest(data, endpoint, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        if (this.landingPageSubscribe) {
            this.makeRequestToLP('POST', 'integrator/subscribe/' + endpoint, payload, onSuccess, onError);
        } else {
            this.makeRequest('POST', endpoint, payload, onSuccess, onError, false);
        }
    }

    customCancelRequest(data, endpoint, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequestCancel('POST', endpoint, payload, onSuccess, onError);
    }

    validateUsernameRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequestToLP('POST', "integrator/username_validate", payload, onSuccess, onError);
    }

    //TODO add ChanegUsername
    changeUsernameRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequestToLP('POST', "integrator/username_change", payload, onSuccess, onError);
    }
    /**
     * Checks if a given identity has a subscription
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    checkSubscriptionRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'lp/subscriptions/check', payload, onSuccess, onError);
    }

    /**
     * Perform a subscribe call as a GET when Header Enrichment is available
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    heSubscribeGetRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('GET', 'lp/he/subscribe', payload, onSuccess, onError);
    }

    /**
     * Perform an OTP generate call
     *
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     * @param internal
     */
    otpGenerateRequest(data, onSuccess, onError, internal = false) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'lp/otp/generate', payload, onSuccess, onError, internal);
    }

    /**
     * Perform an OTP generate call
     * and specify a endpoint
     *
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     * @param internal
     */
    otpGenerateCustomRequest(data, endpoint, onSuccess, onError, internal = false) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', endpoint, payload, onSuccess, onError, internal);
    }

    LPOtpGenerateCustomRequest(data, endpoint, onSuccess, onError = false) {
        const payload = Object.assign({}, this.baseData, data);
        if (this.landingPageSubscribe) {
            this.makeRequestToLP('POST', 'integrator/generate/' + endpoint, payload, onSuccess, onError, true);
        } else {
            this.makeRequest('POST', endpoint, payload, onSuccess, onError, false);
        }
    }

    /**
     * Perform an OTP validate call
     *
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     * @param internal
     */
    otpSubscribeRequest(data, onSuccess, onError, internal = false) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'lp/otp/subscribe', payload, onSuccess, onError, internal);
    }

    /**
     * Perform an OTP validate call
     * and specify a endpoint
     *
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     * @param internal
     */
     otpSubscribeCustomRequest(data, endpoint, onSuccess, onError, internal = false) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', endpoint, payload, onSuccess, onError, internal);
    }


    /**
     * Perform a PIN generate call
     *
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    pinGenerateRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'lp/pin/generate', payload, onSuccess, onError);
    }

    /**
     * Perform an PIN validate call
     *
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    pinSubscribeRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'lp/pin/subscribe', payload, onSuccess, onError);
    }

    /**
     * Perform a Subscribe request
     *
     * @param {Object} data
     * @param {function} onSuccess
     * @param {function} onError
     */
    subscribeRequest(data, onSuccess, onError) {
        const payload = Object.assign({}, this.baseData, data);
        this.makeRequest('POST', 'subscribe', payload, onSuccess, onError);
    }

    /**
     * Send notification request
     *
     * @param data
     * @param onSuccess
     * @param onError
     */
    sendNotificationRequest(data, onSuccess, onError) {
        this.makeRequest('POST', 'api/cim', data, onSuccess, onError);
    }

    /**
     * Sends a request to the integrator
     *
     * @param {string}   type      Type of request (GET or POST)
     * @param {string}   endpoint  API endpoint (e.g. 'lp/init/rendered')
     * @param {Object}   data      Object of data to be sent
     * @param {function} onSuccess Callback to be called when request is successful
     * @param {function} onError   Callback for when an error occurs
     * @param internal
     */
    makeRequest(type, endpoint, data, onSuccess, onError, internal = false) {
        this.logger.info(`Integrator: preparing request to ${endpoint} with verb ${type}`);

        const xhr = new XMLHttpRequest();
        const verb = type === 'GET' ? 'GET' : 'POST';
        const path = `${!internal ? this.baseUrl : ''}/${endpoint}`;
        const requestData = data;
        let requestParams = {};
        xhr.timeout = 40000;

        // Stringify metadata
        if (typeof data.metadata !== 'undefined') {
            const props = Object.keys(data.metadata);
            for (let i = 0; i < props.length; i += 1) {
                requestData.metadata[props[i]] = String(data.metadata[props[i]]);
            }

            if (verb === 'GET') {
                requestData.metadata = encodeURIComponent(
                    btoa(JSON.stringify(requestData.metadata))
                );
            }
        }

        if (requestData.requestParams) {
            requestParams = requestData.requestParams
        }

        if (verb === 'GET') {
            requestParams = {
                ...requestData,
                ...requestParams
            };
        }

        const requestBody = verb === 'GET' ? null : JSON.stringify(requestData);
        const queryString = Object.keys(requestParams).length !== 0 ? `?${Utilities.serialize(requestParams)}` : '';

        this.logger.info(`Integrator: making Request ${verb} ${path}${queryString}`, requestData);

        xhr.open(verb, path + queryString);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.onerror = () => {
            this.logger.error(`Integrator: got ERROR calling  ${verb} ${path}`, requestData, xhr.response);
            let payload = {};
            try {
                payload = JSON.parse(xhr.response);
                payload.response_string = xhr.response;
            } catch (error) {
                payload = { reference: null, status_code: xhr.status, message: 'could not parse integrator response', response_string: xhr.response, error: error };
            }
            onError(payload);
        };
        xhr.ontimeout = () => {
            this.logger.error(`Integrator: got TIMEOUT calling  ${verb} ${path}`, requestData);
            onError({ reference: null, message: 'timeout' });
        };
        xhr.onreadystatechange = () => {
            // if request is done
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const xhrStatus = xhr.status;
                if (xhrStatus === 200) {
                    this.logger.info(`Integrator: received response  ${verb} ${path}`, xhr.response);
                    onSuccess(JSON.parse(xhr.response));
                } else {
                    this.logger.error(`Integrator: expected XHR response status 200, got ${xhrStatus}. full response:`, xhr.response);
                    let payload = {};
                    try {
                        payload = JSON.parse(xhr.response);
                        payload.response_string = xhr.response;
                    } catch (error) {
                        payload = { reference: null, status_code: xhrStatus, message: 'could not parse integrator response', response_string: xhr.response, error: error };
                    }
                    onError(payload);
                }
            }
        };

        xhr.send(requestBody);
    }

    /**
     * Sends a request to the integrator
     *
     * @param {string}   type      Type of request (GET or POST)
     * @param {string}   endpoint  API endpoint (e.g. 'lp/init/rendered')
     * @param {Object}   data      Object of data to be sent
     * @param {function} onSuccess Callback to be called when request is successful
     * @param {function} onError   Callback for when an error occurs
     * @param internal
     */
    makeRequestWithAuth(type, url, auth, data, onSuccess, onError, internal = false) {
        this.logger.info(`Integrator: preparing request to ${url} with verb ${type}`);

        const xhr = new XMLHttpRequest();
        const verb = type === 'GET' ? 'GET' : 'POST';
        const path = url;
        const requestData = data;
        xhr.timeout = 40000;

        const requestBody = null;
        const queryString = "";

        this.logger.info(`Integrator: making Request ${verb} ${path}${queryString}`, requestData);

        xhr.open(verb, path + queryString);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('X-Source-System', 'he-partner');
        xhr.setRequestHeader('x-messageid', '1234');
        xhr.setRequestHeader('X-App', 'he-partner');
        xhr.setRequestHeader('Authorization', 'Bearer ' + auth);
        // xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.onerror = () => {
            this.logger.error(`Integrator: got ERROR calling  ${verb} ${path}`, requestData, xhr.response);
            let payload = {};
            try {
                payload = JSON.parse(xhr.response);
                payload.response_string = xhr.response;
            } catch (error) {
                payload = { reference: null, status_code: xhr.status, message: 'could not parse integrator response', response_string: xhr.response, error: error };
            }
            onError(payload);
        };
        xhr.ontimeout = () => {
            this.logger.error(`Integrator: got TIMEOUT calling  ${verb} ${path}`, requestData);
            onError({ reference: null, message: 'timeout' });
        };
        xhr.onreadystatechange = () => {
            // if request is done
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const xhrStatus = xhr.status;
                if (xhrStatus === 200) {
                    this.logger.info(`Integrator: received response  ${verb} ${path}`, xhr.responseText);
                    onSuccess(JSON.parse(xhr.response));
                } else {
                    this.logger.error(`Integrator: expected XHR response status 200, got ${xhrStatus}. full response: ${xhr.responseText}`);
                    let payload = {};
                    try {
                        payload = JSON.parse(xhr.response);
                        payload.response_string = xhr.responseText;
                    } catch (error) {
                        payload = { reference: null, status_code: xhrStatus, message: 'could not parse integrator response', response_string: xhr.responseText, error: error };
                    }
                    onError(payload);
                }
            }
        };

        xhr.send(requestBody);
    }


    /**
     * Sends a request to the integrator
     *
     * @param {string}   type      Type of request (GET or POST)
     * @param {string}   endpoint  API endpoint (e.g. 'lp/init/rendered')
     * @param {Object}   data      Object of data to be sent
     * @param {function} onSuccess Callback to be called when request is successful
     * @param {function} onError   Callback for when an error occurs
     * @param internal
     */
    makeRequestToLP(type, endpoint, data, onSuccess, onError, internal = false) {
        this.logger.info(`Integrator: preparing request to ${endpoint} with verb ${type}`);

        const xhr = new XMLHttpRequest();
        const verb = type === 'GET' ? 'GET' : 'POST';
        const url = new URL(window.location.origin);
        url.pathname = endpoint;
        const path = url.toString();
        const requestData = data;
        let requestParams = {};
        xhr.timeout = 40000;

        // Stringify metadata
        if (typeof data.metadata !== 'undefined') {
            const props = Object.keys(data.metadata);
            for (let i = 0; i < props.length; i += 1) {
                requestData.metadata[props[i]] = String(data.metadata[props[i]]);
            }

            if (verb === 'GET') {
                requestData.metadata = encodeURIComponent(
                    btoa(JSON.stringify(requestData.metadata))
                );
            }
        }

        if (requestData.requestParams) {
            requestParams = requestData.requestParams
        }

        if (verb === 'GET') {
            requestParams = {
                ...requestData,
                ...requestParams
            };
        }

        const requestBody = verb === 'GET' ? null : JSON.stringify(requestData);
        const queryString = Object.keys(requestParams).length !== 0 ? `?${Utilities.serialize(requestParams)}` : '';

        this.logger.info(`Integrator: making Request ${verb} ${path}${queryString}`, requestData);

        xhr.open(verb, path + queryString);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.onerror = () => {
            this.logger.error(`Integrator: got ERROR calling  ${verb} ${path}`, requestData, xhr.response);
            let payload = {};
            try {
                payload = JSON.parse(xhr.response);
                payload.response_string = xhr.response;
            } catch (error) {
                payload = { reference: null, status_code: xhr.status, message: 'could not parse integrator response', response_string: xhr.response, error: error };
            }
            onError(payload);
        };
        xhr.ontimeout = () => {
            this.logger.error(`Integrator: got TIMEOUT calling  ${verb} ${path}`, requestData);
            onError({ reference: null, message: 'timeout' });
        };
        xhr.onreadystatechange = () => {
            // if request is done
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const xhrStatus = xhr.status;
                if (xhrStatus === 200) {
                    this.logger.info(`Integrator: received response  ${verb} ${path}`, xhr.response);
                    onSuccess(JSON.parse(xhr.response));
                } else {
                    this.logger.error(`Integrator: expected XHR response status 200, got ${xhrStatus}. full response:`, xhr.response);
                    let payload = {};
                    try {
                        payload = JSON.parse(xhr.response);
                        payload.response_string = xhr.response;
                    } catch (error) {
                        payload = { reference: null, status_code: xhrStatus, message: 'could not parse integrator response', response_string: xhr.response, error: error };
                    }
                    onError(payload);
                }
            }
        };

        xhr.send(requestBody);
    }

    /**
     * Sends a request to the integrator
     *
     * @param {string}   type      Type of request (GET or POST)
     * @param {string}   endpoint  API endpoint (e.g. 'lp/init/rendered')
     * @param {Object}   data      Object of data to be sent
     * @param {function} onSuccess Callback to be called when request is successful
     * @param {function} onError   Callback for when an error occurs
     * @param internal
     */
    makeRequestCancel(type, endpoint, data, onSuccess, onError, internal = false) {
        this.logger.info(`Integrator: preparing request to ${endpoint} with verb ${type}`);

        const xhr = new XMLHttpRequest();
        const verb = type === 'GET' ? 'GET' : 'POST';
        const path = `${!internal ? this.baseUrl : ''}/${endpoint}`;
        const requestData = data;
        let requestParams = {};
        xhr.timeout = 40000;

        // Stringify metadata
        if (typeof data.metadata !== 'undefined') {
            const props = Object.keys(data.metadata);
            for (let i = 0; i < props.length; i += 1) {
                requestData.metadata[props[i]] = String(data.metadata[props[i]]);
            }

            if (verb === 'GET') {
                requestData.metadata = encodeURIComponent(
                    btoa(JSON.stringify(requestData.metadata))
                );
            }
        }

        if (requestData.requestParams) {
            requestParams = requestData.requestParams
        }

        if (verb === 'GET') {
            requestParams = {
                ...requestData,
                ...requestParams
            };
        }

        const requestBody = verb === 'GET' ? null : JSON.stringify(requestData);
        const queryString = Object.keys(requestParams).length !== 0 ? `?${Utilities.serialize(requestParams)}` : '';

        this.logger.info(`Integrator: making Request ${verb} ${path}${queryString}`, requestData);

        xhr.open(verb, path + queryString);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.onerror = () => {
            this.logger.error(`Integrator: got ERROR calling  ${verb} ${path}`, requestData, xhr.response);
            let payload = {};
            try {
                payload = JSON.parse(xhr.response);
                payload.response_string = xhr.response;
            } catch (error) {
                payload = { reference: null, status_code: xhr.status, message: 'could not parse integrator response', response_string: xhr.response, error: error };
            }
            onError(payload);
        };
        xhr.ontimeout = () => {
            this.logger.error(`Integrator: got TIMEOUT calling  ${verb} ${path}`, requestData);
            onError({ reference: null, message: 'timeout' });
        };
        xhr.onreadystatechange = () => {
            // if request is done
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const xhrStatus = xhr.status;
                if (xhrStatus === 200) {
                    this.logger.info(`Integrator: received response  ${verb} ${path}`, xhr.response);
                    onSuccess();
                } else {
                    this.logger.error(`Integrator: expected XHR response status 200, got ${xhrStatus}. full response:`, xhr.response);
                    let payload = {};
                    try {
                        payload = JSON.parse(xhr.response);
                        payload.response_string = xhr.response;
                    } catch (error) {
                        payload = { reference: null, status_code: xhrStatus, message: 'could not parse integrator response', response_string: xhr.response, error: error };
                    }
                    onError(payload);
                }
            }
        };

        xhr.send(requestBody);
    }

    makeRequestTest(type, endpoint, data, onSuccess, onError, internal = false) {
        this.logger.info(`Integrator: preparing request to ${endpoint} with verb ${type}`);

        const xhr = new XMLHttpRequest();
        const verb = type === 'GET' ? 'GET' : 'POST';
        const path = `${!internal ? this.baseUrl : ''}/${endpoint}`;
        const requestData = data;
        let requestParams = {};
        xhr.timeout = 40000;

        // Stringify metadata
        if (typeof data.metadata !== 'undefined') {
            const props = Object.keys(data.metadata);
            for (let i = 0; i < props.length; i += 1) {
                requestData.metadata[props[i]] = String(data.metadata[props[i]]);
            }

            if (verb === 'GET') {
                requestData.metadata = encodeURIComponent(
                    btoa(JSON.stringify(requestData.metadata))
                );
            }
        }

        if (requestData.requestParams) {
            requestParams = requestData.requestParams
        }

        if (verb === 'GET') {
            requestParams = {
                ...requestData,
                ...requestParams
            };
        }

        const requestBody = verb === 'GET' ? null : JSON.stringify(requestData);
        const queryString = Object.keys(requestParams).length !== 0 ? `?${Utilities.serialize(requestParams)}` : '';

        this.logger.info(`Integrator: makingRequest ${verb} ${path}${queryString}`, requestData);

        xhr.open(verb, path + queryString);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.onerror = () => {
            this.logger.error(`Integrator: got ERROR calling  ${verb} ${path}`, requestData, xhr.response);
            let payload = {};
            let payloadResponse;
            let status;
            try {
                payloadResponse = xhr.response;
                status = xhr.status;
                this.logger.info("[onError] Trying to parse integrator response", payloadResponse, status)
                payload = JSON.parse(payloadResponse);
                payload.response_string = payloadResponse;
            } catch (error) {
                payload = { reference: null, status_code: status, message: '[onError] could not parse integrator response', response_string: payloadResponse, error: error };
            }
            onError(payload);
        };
        xhr.ontimeout = () => {
            this.logger.error(`Integrator: got TIMEOUT calling  ${verb} ${path}`, requestData);
            onError({ reference: null, message: 'timeout' });
        };
        xhr.onreadystatechange = () => {
            // if request is done
            if (xhr.readyState === XMLHttpRequest.DONE) {
                const xhrStatus = xhr.status;
                if (xhrStatus === 200) {
                    this.logger.info(`Integrator: received response  ${verb} ${path}`, xhr.response);
                    onSuccess(JSON.parse(xhr.response));
                } else {
                    this.logger.error(`Integrator: expected XHR response status 200, got ${xhrStatus}. full response:`, xhr.response);
                    let payload = {};
                    let payloadResponse;
                    let status;
                    try {
                        payloadResponse = xhr.response;
                        status = xhr.status;
                        this.logger.info("[onReadyStateChange] Trying to parse integrator response", payloadResponse, status)
                        payload = JSON.parse(payloadResponse);
                        payload.response_string = payloadResponse;
                    } catch (error) {
                        payload = { reference: null, status_code: status, message: '[onReadyStateChange] could not parse integrator response', response_string: payloadResponse, error: error };
                    }
                    onError(payload);
                }
            }
        };

        xhr.send(requestBody);
    }



    /**
     * Was the user redirected to the LP from the integrator?
     */
    isRedirectRequest() {
        return (typeof this.requestData.integrator_request !== 'undefined' && this.requestData.integrator_request);
    }

    /**
     * Parse the data received from the integrator
     * (if this has been a request to a LP from the Integrator)
     */
    parseRedirectRequest() {
        let request;

        switch (this.requestData.integrator_request) {
        case IntegratorAPI.INTEGRATOR_REQUEST_SUBSCRIBE:
            if (this.requestData.integrator_status === IntegratorAPI.INTEGRATOR_STATUS_SUCCESS) {
                request = new IntegratorAPI.SubscribeRequest(this.requestData);
            } else {
                request = new IntegratorAPI.SubscribeErrorRequest(this.requestData);
            }
            break;

        case IntegratorAPI.INTEGRATOR_REQUEST_IDENTIFY:
            if (this.requestData.integrator_status === IntegratorAPI.INTEGRATOR_STATUS_SUCCESS) {
                request = new IntegratorAPI.IdentifyRequest(this.requestData);
            } else {
                request = new IntegratorAPI.IdentifyErrorRequest(this.requestData);
            }
            break;

        case IntegratorAPI.INTEGRATOR_REQUEST_USER_CANCELLED:
            if (this.requestData.integrator_status === IntegratorAPI.INTEGRATOR_STATUS_SUCCESS) {
                request = new IntegratorAPI.UserCancelledRequest(this.requestData);
            } // We do not expect an error for user cancelled
            break;

        default:
            this.logger.error('Unknown integrator request: ', { requestData: this.requestData });
            return null;
        }

        // Validate request
        if (typeof request === 'undefined' || !request.isValid()) {
            this.logger.error('Invalid request: ', { requestData: this.requestData, parsedRequest: request });
            return null;
        }

        this.logger.debug('Integrator request parsed: ', { requestData: this.requestData, parsedRequest: request });
        return request;
    }

    /**
     *  Loads an iframe
     * @param id
     * @param endpoint
     * @param data
     */
    loadIframe(id, endpoint, data) {
        this.loadUrlIframe(id, this.baseUrl, endpoint, data);
    }

    /* eslint-disable class-methods-use-this */

    /**
     *  Loads an iframe
     * @param id
     * @param url
     * @param endpoint
     * @param data
     */
    loadUrlIframe(id, url, endpoint, data) {
        const requestData = data;
        // Stringify metadata
        if (typeof data.metadata !== 'undefined') {
            const props = Object.keys(data.metadata);
            for (let i = 0; i < props.length; i += 1) {
                requestData.metadata[props[i]] = String(data.metadata[props[i]]);
            }

            requestData.metadata = btoa(JSON.stringify(requestData.metadata));
        }

        const queryString = typeof requestData !== 'undefined' && Object.keys(requestData).length ? `?${Utilities.serialize(requestData)}` : '';
        const path = `${url}/${endpoint}${queryString}`;
        if (document.getElementById(id) === null) {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('id', id);
            iframe.src = path;
            iframe.name = id;
            document.body.appendChild(iframe);
        } else {
            const iframe = document.getElementById(id);
            iframe.src = path;
            iframe.name = id;
        }
    }

    deleteIdentityFrame() {
        let iframe = document.getElementById('identify-frame')
        iframe.remove()
    }
}
export default Integrator;
