import * as events from './events';
import Slider from './slider';

export default class UIControl {
    /**
     * @param {Object} translations
     * @param {Dispatcher} dispatcher
     * @param {Logger} logger
     */
    constructor(translations, dispatcher, logger) {
        this.dispatcher = dispatcher;
        this.logger = logger;
        this.controls = {
            iframe: document.getElementById('flow-iframe'),
            subscribe: document.getElementById('flow-subscribe'),
            terms: {
                container: document.getElementById('flow-terms'),
                checkbox: document.getElementById('flow-terms-checkbox'),
            },
            lander: document.getElementById('flow-lander'),
            continue: document.getElementById('flow-continue'),
            confirm: this.getFlowConfirmElement(),
            contentClick: {
                button: document.getElementById('flow-content-click'),
                container: document.getElementById('content-click-container'),
                contentClickExit: document.getElementById('content-click-exit'),
                contentClickModalBackdrop: document.getElementById('content-click-modal-backdrop')
            },
            consent: {
                consentTickbox: document.getElementById('consentClickBox'),
                consentTickboxMessage: document.getElementById('consentTickboxMessage'),
            },
            optin: document.getElementById('flow-optin'),
            optin_confirm: document.getElementById('flow-optin-confirm'),
            wifi: document.getElementById('flow-wifi'),
            product_login: document.getElementById('flow-product-login'),
            spinner: document.getElementsByClassName('spinner').item(0),
            msisdnReturned: document.getElementsByClassName('users-msisdn-returned').item(0),
            username: {
                container: document.getElementById('flow-username'),
                error: document.getElementById('flow-username-error'),
                username: document.getElementById('flow-username-entry'),
                change: document.getElementById('flow-username-change'),
                submit: document.getElementById('flow-username-submit'),
            },
            otp: {
                heotp: {
                    container: document.getElementById('flow-he-otp-challenge'),
                    submit: document.getElementById('he-otp-challenge'),
                    error: document.getElementById('flow-he-otp-error'),
                },
                container: document.getElementById('flow-otp'),
                error: document.getElementById('flow-otp-error'),
                challengeError: document.getElementById('flow-otp-challenge-error'),
                challenge: document.getElementById('flow-otp-challenge'),
                msisdn: document.getElementById('otp-msisdn'),
                validate: document.getElementById('flow-otp-validate-challenge'),
                code: document.getElementById('otp-code'),
                emptycodeerror: document.getElementById('otp-empty-error'),
                buttons: {
                    challenge: document.getElementById('otp-challenge'),
                    confirm: document.getElementById('otp-confirm'),
                    validate: document.getElementById('otp-validate'),
                },
            },
            preRedirect: {
                container: document.getElementById('flow-pre-redirect'),
                link: document.getElementsByClassName('pre-redirect-link').item(0),
            },
            pin: {
                container: document.getElementById('flow-pin'),
                error: document.getElementById('flow-pin-error'),
                validate: document.getElementById('flow-pin-validate'),
                image: document.getElementById('pin-image'),
                code: document.getElementById('pin-code'),
            },
            external: document.getElementById('flow-external'),
            success: {
                container: document.getElementById('flow-success'),
                continue: document.getElementById('flow-success-continue'),
            },
            card: {
                initiator: document.getElementById('flow-card-initiator'),
                container: document.getElementById('flow-card'),
                loading: document.getElementById('card-loading'),
                success: document.getElementById('card-success'),
                cancelled: document.getElementById('card-cancelled'),
                error: document.getElementById('card-error'),
            },
            languages: document.getElementById('languages'),
            plan: document.getElementById('flow-plan'),
            slider: {
                sliderImagesContainer: document.getElementById('slider-container'),
                secondBGImageContainer: document.getElementById('second-img-container'),
                BGImageOne: document.getElementById('slider-bg-image-1'),
                BGImageTwo: document.getElementById('slider-bg-image-2'),
                sliderElement: document.getElementById('img-slider')
            },
            universalLogin: {
                buttonsContainer: document.getElementById('buttons-container'),
                loginButton: document.getElementById('flow-login'),
                subscribeButton: document.getElementById('flow-subscribe'),
            },
            tryForFree: document.getElementById('flow-try-for-free'),
            exit: document.getElementById('flow-exit')
        };
        this.translations = translations;

        this.bindClickEvents();
        this.bindTrackingEvents();
    }

    getFlowConfirmElement() {
        if (document.getElementById('protected')) {
            return document.getElementById('protected');
        }
        return document.getElementById('flow-confirm')
    }

    isImageSameSize(img1, img2) {
        return !(
            typeof img1.clientWidth == "undefined"
            || typeof img2.clientWidth == "undefined"
            || img1.clientWidth !== img2.clientWidth
        );
    }

    /**
     * Bind click events on the flow buttons (wifi button, subscribe button, confirm button)
     */
    bindClickEvents() {
        this.logger.debug('UIControl: binding click events on elements');

        // Listen for plan chosen
        if (this.controls.plan) {
            const plans = this.controls.plan.querySelectorAll('.plan');
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < plans.length; i++) {
                plans[i].addEventListener('click', () => {
                    this.logger.debug(`Plan chosen: ${plans[i].value}`);
                    this.dispatcher.dispatchEvent(events.PLAN_CHOSEN, plans[i].value);
                });
            }
        }

        if (
            this.controls.slider.sliderImagesContainer
            && this.isImageSameSize(this.controls.slider.BGImageOne, this.controls.slider.BGImageTwo)
        ) {
            let startFromZero = this.controls.slider.secondBGImageContainer.classList.contains('start-from-zero')

            const slider = new Slider(
                90,
                this.controls.slider.sliderImagesContainer,
                this.controls.slider.BGImageOne,
                this.controls.slider.BGImageTwo,
                this.controls.slider.sliderElement,
                this.controls.slider.secondBGImageContainer,
                startFromZero
            );
            slider.initComparisons();
            this.controls.slider.sliderElement.addEventListener('sliderTrigger', (e) => {
                this.dispatcher.dispatchEvent(events.SLIDER_TRIGGER, e)
            })
        }

        if(this.controls.consent.consentTickbox){
            this.controls.consent.consentTickbox.addEventListener('change', () => {
                this.dispatcher.dispatchEvent(events.CONSENT_TICKBOX_CHANGED)
            })
        }

        // Listen for lander click
        if (this.controls.lander) {
            this.controls.lander.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.LANDER_CLICK);
            });
        }

        // Listen for username submit click
        if (this.controls.username.submit) {
            this.controls.username.submit.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.USERNAME_SUBMIT);
            });
        }

        // Listen for username submit click
        if (this.controls.username.change) {
            this.controls.username.change.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.USERNAME_CHANGE);
            });
        }

        // Listen for first click
        if (this.controls.subscribe) {
            this.controls.subscribe.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.FIRST_CLICK);
            });
        }

        if (this.controls.tryForFree) {
            this.controls.tryForFree.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.TRY_FOR_FREE)
            })
        }

        // Listen for second click
        if (this.controls.confirm) {
            this.controls.confirm.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.SECOND_CLICK);
            });
        }

        if(this.controls.exit){
            this.controls.exit.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.CANCEL_CLICK);
            });
        }

        if(this.controls.contentClick.contentClickExit){
            this.controls.contentClick.contentClickExit.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.CANCEL_CLICK);
            });
        }

        //Listen for content click
        if (this.controls.contentClick.button) {
            this.controls.contentClick.button.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.CONTENT_CLICK)
            })
        }

        // Listen for second click
        if (this.controls.optin_confirm) {
            this.controls.optin_confirm.addEventListener('click', () => {
                if (this.controls.optin.checked) {
                    this.dispatcher.dispatchEvent(events.OPTIN_CONFIRM);
                }
            });
        }

        // Listen for continue click
        if (this.controls.continue) {
            this.controls.continue.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.CONTINUE_CLICK);
            });
        }
        if (this.controls.success && this.controls.success.continue) {
            this.controls.success.continue.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.CONTINUE_CLICK);
            });
        }

        // Listen for terms checked
        if (this.controls.terms.checkbox) {
            this.controls.terms.checkbox.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(
                    this.controls.terms.checkbox.checked
                        ? events.TERMS_CHECKED
                        : events.TERMS_UNCHECKED
                );
            });
        }

        // Listen for optin checked
        if (this.controls.optin) {
            this.controls.optin.addEventListener('change', () => {
                this.dispatcher.dispatchEvent(
                    this.controls.optin.checked
                        ? events.OPTIN_CHECKED
                        : events.OPTIN_UNCHECKED
                );
            });
        }

        // Listen for wifi click
        if (this.controls.wifi) {
            this.controls.wifi.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.WIFI_CLICK);
            });
        }

        // Listen for product login click
        if (this.controls.product_login) {
            this.controls.product_login.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.PRODUCT_LOGIN_CLICK);
            });
        }

        // Listen for OTP clicks
        if (this.controls.otp.container) {
            if (this.controls.otp.buttons.challenge) {
                this.controls.otp.buttons.challenge.addEventListener('click', () => {
                    this.dispatcher.dispatchEvent(events.OTP_CHALLENGE_SUBMIT);
                });
            }

            if (this.controls.otp.heotp.submit) {
                this.controls.otp.heotp.submit.addEventListener('click', () => {
                    this.dispatcher.dispatchEvent(events.HE_OTP_CHALLENGE_SUBMIT);
                });
            }

            if (this.controls.otp.buttons.confirm) {
                this.controls.otp.buttons.confirm.addEventListener('click', () => {
                    this.dispatcher.dispatchEvent(events.OTP_CHALLENGE_CONFIRM);
                });
            }

            if (this.controls.otp.buttons.validate) {
                this.controls.otp.buttons.validate.addEventListener('click', () => {
                    this.dispatcher.dispatchEvent(events.OTP_CHALLENGE_VALIDATE);
                });
            }
        }

        // Listen for PIN clicks
        if (this.controls.pin.container) {
            const pinChallengeValidate = document.getElementById('pin-validate');
            if (pinChallengeValidate) {
                pinChallengeValidate.addEventListener('click', () => {
                    this.dispatcher.dispatchEvent(events.PIN_CHALLENGE_VALIDATE);
                });
            }
        }
        if (this.controls.otp.code && this.controls.otp.code.hasAttribute('autocomplete')) {
            this.dispatcher.addEventListener(events.OTP_CHALLENGE_SUBMIT, () => {
                if (navigator.credentials !== undefined) {
                    const ac = new AbortController();
                    navigator.credentials.get({
                        otp: { transport: ['sms'] },
                        signal: ac.signal
                    }).then(otp => {
                        this.controls.otp.code.value = otp.code;
                    }).catch(err => {
                        console.error(err);
                        this.showElement(this.controls.otp.error);
                    });
                }
            })
            this.dispatcher.addEventListener(events.HE_OTP_CHALLENGE_SUBMIT, () => {
                if (navigator.credentials !== undefined) {
                    const ac = new AbortController();
                    navigator.credentials.get({
                        otp: { transport: ['sms'] },
                        signal: ac.signal
                    }).then(otp => {
                        this.controls.otp.code.value = otp.code;
                    }).catch(err => {
                        console.error(err);
                        this.showElement(this.controls.otp.error);
                    });
                }
            })
        }

        // Listen for clicks on External button
        if (this.controls.external) {
            this.controls.external.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.OPEN_EXTERNAL_CLICK);
            });
        }

        // Listen for clicks on Pay By Card button
        if (this.controls.card.initiator) {
            this.controls.card.initiator.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.PAY_BY_CARD_CLICK);
            });
        }

        // Listen for clicks on Universal Login buttons
        if (this.controls.universalLogin.loginButton) {
            this.controls.universalLogin.loginButton.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.UNIVERSAL_LOGIN_LOGIN_BUTTON_CLICKED, {});
            });
        }

        if (this.controls.universalLogin.subscribeButton) {
            this.controls.universalLogin.subscribeButton.addEventListener('click', () => {
                this.dispatcher.dispatchEvent(events.UNIVERSAL_LOGIN_SIGNUP_BUTTON_CLICKED, {});
            });
        }

        // Listen for clicks on language buttons
        if (this.controls.languages) {
            const languages = this.controls.languages.getElementsByClassName('language-option');
            if (languages.length) {
                for (let i = 0; i < languages.length; i += 1) {
                    const language = languages[i];
                    language.addEventListener('click', (event) => {
                        event.preventDefault();
                        this.dispatcher.dispatchEvent(events.LANGUAGE_SWITCHED, {
                            href: language.getAttribute('href'),
                            locale: language.dataset.locale,
                        });
                    });
                }
            }
        }
    }

    checkSmsCredentials() {
        if (navigator.credentials !== undefined) {
            if (this.controls.otp.code.value) {
                return // return early if the box has a value in it
            }
            const ac = new AbortController();
            navigator.credentials.get({
                otp: {transport: ['sms']},
                signal: ac.signal
            }).then(otp => {
                this.controls.otp.code.value = otp.code;
            }).catch(err => {
                console.error(err);
                this.showElement(this.controls.otp.error);
            });
        } else {
            this.logger.info("navigator credentials not set")
        }
    }

    bindTrackingEvents() {
        this.logger.debug('UIControl: binding tracking events on elements');

        // Listen for iframe loaded events
        if (this.controls.iframe) {
            this.controls.iframe.addEventListener('load', () => {
                this.dispatcher.dispatchEvent(events.IFRAME_LOADED);
            });
        }
    }

    /**
     * Toggle the visibility of an element
     * @param {Object} element
     * @param {boolean} visible
     */
    // eslint-disable-next-line class-methods-use-this
    toggleElement(element, visible) {
        if (!element) {
            return;
        }

        let show = visible;

        if (arguments.length === 1) {
            show = !element.classList.contains('hide') || element.classList.contains('show');
        }

        if (show) {
            element.classList.add('show');
            element.classList.remove('hide');
        } else {
            element.classList.remove('show');
            element.classList.add('hide');
        }
    }

    /**
     * Show the given element
     * @param {Object} element
     */
    showElement(element) {
        this.logger.debug('UIControl: showing element', element);
        this.toggleElement(element, true);
    }

    /**
     * Hide the given element
     * @param {Object} element
     */
    hideElement(element) {
        this.logger.debug('UIControl: hiding element', element);
        this.toggleElement(element, false);
    }

    /**
     * Shows an error message with a reload button
     *
     * @param messageKey
     * @param buttonText
     */
    showErrorMessage(messageKey, buttonText) {
        const btnTxt = typeof buttonText !== 'undefined' ? buttonText : 'RELOAD';
        const onClick = () => {
            const [basePath] = window.location.href.split('?');
            window.location = basePath;
        };
        return this.displayMessageWithButton(messageKey, btnTxt, 'error', onClick);
    }

    showCloseMessage(messageKey, buttonText) {
        const btnTxt = typeof buttonText !== 'undefined' ? buttonText : 'CLOSE';
        const onClick = () => {
            window.location.replace('https://google.com');
        };
        return this.displayMessageWithButton(messageKey, btnTxt, 'close', onClick);
    }

    /**
     * Show an error message with a reload button
     * @param {string} messageKey String key to be used to retrieve the correct translated message
     * @param {string} buttonText Text to be used on the message action button
     * @param {string} containerClass A CSS class to be added on the message container
     * @param {Function} onClick Message button click handler
     */
    displayMessageWithButton(messageKey, buttonText, containerClass, onClick) {
        const flowElem = document.getElementById('flow');

        const messageContainer = document.createElement('div');
        messageContainer.classList.add('message-container', containerClass);

        const msg = document.createElement('p');
        msg.innerText = this.translate(messageKey);
        msg.classList.add('copy', 'alert');
        messageContainer.appendChild(msg);

        const btn = document.createElement('button');
        btn.innerText = this.translate(buttonText);
        btn.classList.add('button-warning', 'message-btn');
        btn.addEventListener('click', onClick);
        messageContainer.appendChild(btn);

        flowElem.innerHTML = '';
        flowElem.appendChild(messageContainer);
    }

    /**
     * Show an error message without a reload button
     * @param {string} messageKey String key to be used to retrieve the correct translated message
     */
    displayMessage(messageKey, containerClass) {
        const flowElem = document.getElementById('flow');

        const messageContainer = document.createElement('div');
        messageContainer.classList.add('message-container', containerClass);

        const msg = document.createElement('p');
        msg.innerText = this.translate(messageKey);
        msg.classList.add('copy', 'alert');
        messageContainer.appendChild(msg);

        flowElem.innerHTML = '';
        flowElem.appendChild(messageContainer);
    }

    /**
     * Show an error message without a reload button
     * @param {string} message String key to be used to retrieve the correct translated message
     */
    displayCustomMessage(message, containerClass) {
        const flowElem = document.getElementById('flow');

        const messageContainer = document.createElement('div');
        messageContainer.classList.add('message-container', containerClass);

        const msg = document.createElement('p');
        msg.innerText = message;
        msg.classList.add('copy', 'alert');
        messageContainer.appendChild(msg);

        flowElem.innerHTML = '';
        flowElem.appendChild(messageContainer);
    }

    showWifiFlow() {
        this.showElement(this.controls.wifi);
    }

    /**
     * Show the wifi flow but instead of linking to the identification URL have a reload page link
     */
    showWifiFlowWithRefreshPage() {
        this.controls.wifi.children[0].setAttribute('href', window.location.href);
        this.showElement(this.controls.wifi);
    }

    showWifiFlowWithRefreshAndLoginLink(productUrl) {
        this.controls.wifi.children[0].setAttribute('href', window.location.href);
        if (this.controls.product_login) {
            this.controls.product_login.children[0].setAttribute('href', productUrl);
        }
        this.showElement(this.controls.wifi);
        this.showElement(this.controls.product_login);
    }

    addHrefToRedirectLink(value) {
        this.controls.preRedirect.link.setAttribute("href", value)
    }

    /**
     * Retrieve the translation for the gven key
     * @param {string} key
     */
    translate(key) {
        return this.translations[key] || key;
    }

    /**
     * Set the state on the body as a data attr
     * @param {string} state
     */
    // eslint-disable-next-line class-methods-use-this
    setPageState(state) {
        document.body.dataset.state = state;
    }

    prefillInput(element, value) {
        element.value=value;
    }

    getCheckedRadioInputValue(name){
        const value = document.querySelector(`input[name=${name}]:checked`)?.value
        if (!value){
            return null
        }
        return value
    }
}
